<template>
  <div class="section__wrapper">
    <RouterLink :to="{ name: 'HOME' }" tag="div" class="page-back">
      <div class="page-back__arrow"></div>
      <div class="page-back__name">
        Проекты
      </div>
    </RouterLink>
    <section>
      <div class="block__head">
         <a href="https://films.reznikovk.ru/" target="_blank">Киносписок</a>
      </div>
      <div class="block__text">
        Список с личными рейтингами фильмов и сервис по составлению собственных рейтингов
      </div>
      <div class="block__head">
        <a href="https://games.reznikovk.ru/" target="_blank">Игросписок</a>
      </div>
      <div class="block__text">
        Список с личными рейтингами игр и сервис по составлению собственных рейтингов
      </div>
      <div class="block__head">
        <a href="https://publications.reznikovk.ru/" target="_blank">Публикации</a>
      </div>
      <div class="block__text">
        Список с личными публикациями и сервис по составлению источников литературы
      </div>
      <div class="block__head">
        <a href="https://ege.reznikovk.ru/" target="_blank">Подготовка по программированию</a>
      </div>
      <div class="block__text">
        Подготовка к экзаменам в школе по информатике и программированию
      </div>
      <div class="block__head">
        <a href="https://mentor.reznikovk.ru/" target="_blank">Ментор</a>
      </div>
      <div class="block__text">
        Помогаю начать и освоиться в IT-карьере и разработке
      </div>
    </section>
  </div>
</template>

<script>
import { helpModal } from "@/mixins/modals";

export default {
  name: 'ProjectsPage',
  mixins: [helpModal],
}
</script>
