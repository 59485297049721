<template>
  <div>
    <PageLayout>
      <RouterView />
    </PageLayout>
    <ModalContainer />
  </div>
</template>

<script>
import ModalContainer from "@/components/parts/ModalContainer";
import PageLayout from "@/components/parts/PageLayout";

export default {
  components: {
    ModalContainer,
    PageLayout
  }
}
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: @cBaseTwo;
}

a {
  color: white;
  text-decoration: underline;
}

.page-back {
  position: absolute;
  display: none;
  top: 0;
  height: 30px;
  width: 100%;
  font-family: 'Press Start 2P', system-ui;
  color: white;

  @media screen and (max-width: 900px) {
    display: flex;
    gap: 10px;
  }

  &__arrow {
    background: rgba(79, 79, 79, 0.85);
    position: relative;
    width: 40px;
    height: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url('@/assets/back.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__name {
    background: rgba(79, 79, 79, 0.85);
    flex: 1;
    line-height: 30px;
    padding: 0 20px;
  }
}

.section__wrapper {
  max-height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
}

section {
  background-color: rgba(79, 79, 79, 0.85);
  box-sizing: border-box;
  overflow-y: scroll;
  margin-right: -30px;
  max-height: 100%;
  width: calc(100% + 30px);
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 40px;

  &::before {
    content: "";
    position: absolute;
    top: 40px;
    width: 100%;
    height: 30px;
    background: linear-gradient(rgba(79, 79, 79, 0.9), transparent);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(transparent, rgba(79, 79, 79, 0.9));
  }
}

h1, h2, h3, h4, h5 {
  font-family: @ffOne;
  color: @cBaseThree;
  margin: 0;
}

h2 {
  font-size: 32px;
}

.p-16 {
  padding: 16px;
}

.d-flex {
  display: flex;
}

.block {
  &__name {
    padding: 0 20px 20px;
    text-align: center;
    color: white;
    font-family: 'Press Start 2P', system-ui;
    font-size: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__head {
    padding: 0 20px 10px;
    color: white;
    font-family: 'Press Start 2P', system-ui;
    font-size: 14px;
    line-height: 18px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__text {
    padding: 0 20px 20px;
    color: white;
    font-family: 'Press Start 2P', system-ui;
    font-size: 10px;
    line-height: 14px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
