import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/pages/Home.vue';
import AboutPage from '../components/pages/About.vue';
import ProjectsPage from '../components/pages/Projects.vue';
import LinksPage from '../components/pages/Links.vue';
import { RouteNames } from './routes';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: HomePage,
        meta: {
            title: 'О, привет! | Резников Константин'
        }
    },
    {
        path: '/about',
        name: RouteNames.ABOUT,
        component: AboutPage,
        meta: {
            title: 'Об авторе | Резников Константин'
        }
    },
    {
        path: '/projects',
        name: RouteNames.PROJECTS,
        component: ProjectsPage,
        meta: {
            title: 'Проекты | Резников Константин'
        }
    },
    {
        path: '/links',
        name: RouteNames.LINKS,
        component: LinksPage,
        meta: {
            title: 'Ссылки | Резников Константин'
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});
router.afterEach((to) => {
    document.title = to.meta?.title || 'О, привет! | Резников Константин';
});
export default router;
